import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { toExcludeApi } from "../../services/api";
import { useNavigate } from 'react-router-dom';

const AddExcludeItem = ({ data, setData, setRefresh }) => {
  const navigate = useNavigate();
  const [item, setItem] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        addItem();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [item]);

  const addItem = async () => {
    if (item) {
      try {
        const addedItem = {
          name: item,
        };
        setDisabled(true);
        await toExcludeApi([addedItem]);
        setRefresh(true)
        // let dataCopy = data.slice(0);
        // dataCopy.push({ item: item });
        // setData(dataCopy);
        setItem("");
      } catch (error) {
        if (Number(error.message) === 401) {
          navigate('/login');
        }
      }
    }
  };

  return (
    <>
      <TextField
        id="standard-basic"
        label="Add Item"
        variant="standard"
        sx={{ align: "left", marginTop: "3%" }}
        value={item}
        onChange={(text) => {
          setDisabled(false);
          setItem(text.target.value);
        }}
      />
      {/* <Select
        value={level}
        onChange={(a) => {
          setLevel(a.target.value);
        }}
        sx={{ width: "15%", height: "5%", marginLeft: "5%" }}
      >
        <MenuItem value={"sport"}>sport</MenuItem>
        <MenuItem value={"region"}>region</MenuItem>
        <MenuItem value={"competition"}>competition</MenuItem>
        <MenuItem value={"market"}>market</MenuItem>
        <MenuItem value={"event"}>event</MenuItem>
      </Select> */}
      <IconButton
        disabled={disabled}
        sx={{ marginTop: "5%", marginLeft: "5%", cursor: "pointer" }}
        onClick={() => {
          addItem();
        }}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

export default AddExcludeItem;
