import { useState, useRef } from "react";

import { useInView } from "react-intersection-observer";
import { InView } from 'react-intersection-observer';


import CopyButton from "./CopyButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MoveToSection from "./MoveToSection";
import CheckboxButton from "./CheckboxButton";
import { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";



const DataTable = ({ data, setData, additionalLoading, setIsVisible, errorsCount, offset }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  // const ref = useRef()

  const [checkedList, setCheckList] = useState([]);

  useEffect(() => {
    setIsVisible(inView)
    console.log(inView);
  }, [inView])

  const columns = ["", "Name", "Level", "Found Date", "Path"];
  useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <>
      <MoveToSection
        data={data}
        setData={setData}
        list={checkedList}
        setCheckedList={setCheckList}
      />
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: "50rem", overflowY: "auto" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {columns.map((name) => (
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.name + Math.floor(Math.random() * 1000000)}>
                <TableCell>
                  <CheckboxButton
                    index={index}
                    element={row}
                    setCheckedList={setCheckList}
                    checkedList={checkedList}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 12px 0 0",
                      gap: "14px",
                    }}
                  >
                    <a
                      href={row?.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {row?.name}
                    </a>
                    <CopyButton text={row?.name} />
                  </Box>
                </TableCell>
                <TableCell align="left">{row?.level}</TableCell>
                <TableCell align="left">{row?.found_date}</TableCell>
                <TableCell align="left">{row?.path}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {errorsCount > offset && (
        <Box>
          <CircularProgress size="2rem" sx={{ padding: "10px" }} />
        </Box>
      )}<br />
      <Card style={{
        backgroundColor: "#fff3f2",
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        fontFamily: "Arial"
      }} ref={ref}>Total errors count {errorsCount}</Card>
    </>
  );
};

export default DataTable;
