import Authentication from './Authentication';

const Login = ({ isAuthenticated, setIsAuthenticated, sessionExpiredIndecator }) => {
  return (
    <div className="container">
      <Authentication
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
        sessionExpiredIndecator={sessionExpiredIndecator}
      />
    </div>
  );
}

export default Login;