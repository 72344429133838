const skinLang = [
  {
    value: "vbet.com",
    languages: ["eng", "rus", "geo", "fra", "arm", "arb"],
  },

  {
    value: "vbet.am",
    languages: ["arm", "rus", "eng", "geo", "fas"],
  },

  {
    value: "vbet.lat",
    languages: ["eng", "spa", "por"],
  },

  {
    value: "vbet.de",
    languages: ["ger", "eng", "tur"],
  },

  {
    value: "vbet.fr",
    languages: ["fra"],
  },

  {
    value: "vbet.co.uk",
    languages: ["eng"],
  },

  {
    value: "vbet10.com",
    languages: ["eng", "hin"],
  },

  {
    value: "vbetcn.com",
    languages: ["zhh", "eng"],
  },

  {
    value: "vbettr.com",
    languages: ["tur", "eng"],
  },

  {
    value: "vbetua.com",
    languages: ["ukr", "rus", "eng"],
  },

  {
    value: "vbet.ua",
    languages: ["ukr", "rus", "eng"],
  },

  {
    value: "vbet.co.it",
    languages: ["ita"],
  },

  {
    value: "vbet.se",
    languages: ["swe"],
  },

  {
    value: "vbetcrypto.com",
    languages: ["tur", "eng"],
  },
  {
    value: "vbetftn.com",
    languages: ["eng", "arm","rus","ukr","spa","por","geo","fra","zhh","arb","hin"],
  },
];

export default skinLang;
