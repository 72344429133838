const STATUS = {
  UNAUTHORIZED: 401,
};
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const fetchNewToken = async () => {

  const token = localStorage.getItem('token');
  const payload = JSON.parse(atob(token.split('.')[1]));
  const expirationDate = new Date(payload.time * 1000);

  if (expirationDate < Date.now()) {
      let response = await fetch( `${REACT_APP_API_URL}/refresh-token`, {
      mode: "cors",
      headers: {
        authentication: localStorage.getItem("token"),
      },
    })
    response = await response.json();
    if (response.jwt) {
      localStorage.setItem("token", response.jwt);
    }  else {
      localStorage.removeItem('token');
    }
  }
};

export const fetchData = async (
  level,
  language,
  section,
  skin,
  count,
  endDate,
  startDate,
  offset,
  errorCallback
) => {
  console.log(process.env);
  let response = await fetch(
    `${REACT_APP_API_URL}/get-data-auth?` +
      new URLSearchParams({
        level: level,
        language: language,
        schema: section,
        skin: skin,
        limit: count,
        end_date: endDate,
        start_date: startDate,
        offset: offset,
      }),
    {
      mode: "cors",
      headers: {
        authentication: localStorage.getItem("token"),
      },
    }
  );
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await fetchData(
      level,
      language,
      section,
      skin,
      count,
      endDate,
      startDate,
      errorCallback
    );
  } else {
    response = await response.json();
    return response.data;
  }
  // if (error.message === STATUS.UNAUTHORIZED) {
  //   localStorage.removeItem("token");
  //   errorCallback();
  // }
};

export const login = async (USER, setError, callback) => {
  try {
    const response = await fetch( `${REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(USER),
    });

    if (response.ok) {
      let token = await response.json();
      if (token.jwt) {
         localStorage.setItem("token", token.jwt);
      }
      callback();
    } else {
      throw new Error("Couldn't log in.");
    }
  } catch (error) {
    setError(error.message);
  }
};

export const fetchExcludeList = async () => {
  let response = await fetch(`${REACT_APP_API_URL}/get-white-list`, {
    mode: "cors",
    headers: {
      authentication: localStorage.getItem("token"),
    },
  });
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await fetchExcludeList();
  } else {
    response = await response.json();
    return response.data;
  }
};

export const toExcludeApi = async (items) => {
  let response = await fetch(
    `${REACT_APP_API_URL}/add-item-to-white-list`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authentication: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        items: items,
      }),
    }
  );
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await toExcludeApi(items);
  } else {
    response = await response.json();
    return response.data;
  }
};

export const deleteExcludeItem = async (item) => {
  let response = await fetch(
    `${REACT_APP_API_URL}/delete-item-from-white-list`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        authentication: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        items: [{ id: item.id }],
      }),
    }
  );
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await deleteExcludeItem(item);
  } else {
    response = await response.json();
    return response.data;
  }
};

export const editExcludeItem = async (editedItem) => {
  let response = await fetch(`${REACT_APP_API_URL}/update-white-list`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authentication: localStorage.getItem("token"),
    },
    body: JSON.stringify({
      items: editedItem,
    }),
  });
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await editExcludeItem(editedItem);
  } else {
    response = await response.json();
    return response.data;
  }
};

export const fetchCountOfErrors = async (skin, language, section) => {
  let response = await fetch(
    `${REACT_APP_API_URL}/get-count-of-errors?skin=${skin}&language=${language}&schema=${section}`,
    {
      mode: "cors",
      headers: {
        authentication: localStorage.getItem("token"),
      },
    }
  );
  if (response.status === STATUS.UNAUTHORIZED) {
    await fetchNewToken();
    return await fetchCountOfErrors(skin, language, section);
  } else {
    response = await response.json();
    return response.count;
  }
};
