import Checkbox from "@mui/material/Checkbox";

const CheckboxButton = ({ index, element, setCheckedList, checkedList }) => {
  const onClick = () => {
    const item = checkedList.find((item) => item.url === element.url);

    if (item) {
      const res = checkedList.filter((st) => st.url !== element.url);
      setCheckedList(res);
      return;
    }
    setCheckedList((prev) => [
      ...prev,
      { name: element.name, index, url: element.url },
    ]);
  };

  return (
    <Checkbox
      checked={checkedList.find((item) => item.url === element.url)}
      onChange={() => {
        onClick();
      }}
    />
  );
};

export default CheckboxButton;
