import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import skinLang from "../../../utility/SkinLang";
import classes from "./Form.module.css";

const Form = (props) => {
  return (
    <>
      <FormControl>
        <InputLabel id="skin">Skin</InputLabel>
        <Select
          labelId="skin"
          id="skin"
          className={classes.select}
          label="Skin"
          value={props.skin}
          onChange={props.onSkinChange}
        >
          {skinLang.map((skin) => (
            <MenuItem key={skin.value} value={skin.value}>
              {skin.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="language">Language</InputLabel>
        <Select
          // disabled={!props.skin}
          labelId="language"
          id="language"
          className={classes.select}
          label="Language"
          value={props.language}
          onChange={props.onLanguageChange}
        >
          {props.skin !== "" &&
            skinLang
              .find((skin) => skin.value === props.skin)
              .languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="section">Section</InputLabel>
        <Select
          // disabled={!props.skin}
          labelId="section"
          id="section"
          className={classes.select}
          label="Section"
          value={props.section}
          onChange={props.onSectionChange}
          selected={true}
        >
          <MenuItem value={"0"}>prematch</MenuItem>
          <MenuItem value={"1"}>live</MenuItem>
          <MenuItem value={"2"}>prematch/live</MenuItem>
        </Select>
      </FormControl>
      {/* <FormControl>
        <InputLabel id="level">Level</InputLabel>
        <Select
          labelId="level"
          id="level"
          className={classes.select}
          label="Level"
          value={props.level}
          onChange={props.onLevelChange}
        >
          <MenuItem value={"sport"}>sport</MenuItem>
          <MenuItem value={"region"}>region</MenuItem>
          <MenuItem value={"competition"}>competition</MenuItem>
          <MenuItem value={"market"}>market</MenuItem>
          <MenuItem value={"event"}>event</MenuItem>
        </Select>
      </FormControl> */}
    </>
  );
};

export default Form;
