import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import { toExcludeApi } from "../../../services/api";
import { useNavigate } from 'react-router-dom';

const MoveToSection = ({ data, setData, list, setCheckedList }) => {
  const navigate = useNavigate();
  const [isMoved, setIsMoved] = useState();

  const toExclude = async () => {
    try {
      const excludeList = list.map((index) => ({
        name: index.name,
      }));
      await toExcludeApi(excludeList);
      setCheckedList([]);
      let copyData = data.slice(0);
      list.forEach(({ index }) => { copyData.splice(index, 1) });
      setData(copyData);
      setIsMoved(true);
      setTimeout(() => {
        setIsMoved(false);
      }, 3000);
    } catch (error) {
      if (Number(error.message) === 401) {
        navigate('/login');
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "30px",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Button
        variant="contained"
        disabled={list.length === 0 ? true : false}
        sx={{ marginBottom: "5%", width: "150px" }}
        onClick={toExclude}
      >
        Move to exclude list
      </Button>

      <Box sx={{ marginBottom: "5%" }}>
        <Collapse orientation="horizontal" in={isMoved}>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            Successfully moved!
            <CheckCircleIcon sx={{ color: "green" }} />
          </Typography>
        </Collapse>
      </Box>
    </Box>
  );
};
export default MoveToSection;
