import React from "react";
import Button from "@mui/material/Button";
import { useLocation, NavLink } from "react-router-dom";

const HeaderButton = ({ name, pathname }) => {
  const location = useLocation();

  return (
    <NavLink
      color="inherit"
      to={"/" + pathname}
      style={{ textDecoration: "none", width: "220px" }}
    >
      <Button
        sx={
          location.pathname === "/" + pathname
            ? {
                marginLeft: "5%",
                background: "#b30f6f",
                color: "white",
                paddingRight: "20px",
                paddingLeft: "20px",
              }
            : { marginLeft: "5%", color: "white" }
        }
      >
        {name}
      </Button>
    </NavLink>
  );
};

export default HeaderButton;
