import React from "react";
import Card from "@mui/material/Card";

const CardWrapper = (props) => {
  return (
    <Card
      sx={{
        width: "100%",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </Card>
  );
};

export default CardWrapper;
