import { useState } from "react";

import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { login } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Authentication = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const onUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const USER = {
    name: username,
    password: password,
  };

  const loginHandler = async () => {
    login(USER, setError, () => {
      props.setIsAuthenticated(true);
      navigate('/issues');
    });
  };

  return (
    <Card sx={{ padding: "4rem", display: "flex", flexFlow: "column", marginTop: "1.5rem" }}>
      {error !== null && (
        <Card
          sx={{
            backgroundColor: "#fff3f2",
            width: "100%",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <p>Couldn't log in.</p>
        </Card>
      )}
      {props.sessionExpiredIndecator && <Card sx={{
        backgroundColor: "#fff3f2",
        width: "100%",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "1rem",
      }}>
        {props.sessionExpiredIndecator && <p>Your session is expired</p>}
      </Card>}
      <FormControl>
        <InputLabel error={error === null ? false : true} htmlFor="username">
          Username
        </InputLabel>
        <Input
          error={error === null ? false : true}
          id="username"
          type="text"
          sx={{ marginBottom: "2rem", width: "20rem" }}
          value={username}
          onChange={onUsernameChange}
        />
      </FormControl>
      <FormControl>
        <InputLabel error={error === null ? false : true} htmlFor="password">
          Password
        </InputLabel>
        <Input
          error={error === null ? false : true}
          id="password"
          type="password"
          sx={{ marginBottom: "2rem", width: "20rem" }}
          value={password}
          onChange={onPasswordChange}
        />
      </FormControl>
      <Button sx={{ width: "5rem" }} onClick={loginHandler}>
        Log In
      </Button>
    </Card>
  );
};

export default Authentication;
