import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";

const DeleteModal = ({ openModal, onDeleteClick, setOpenModal, item }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Are you sure you want to delete item?
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onDeleteClick(item.id);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;
