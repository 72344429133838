import { useEffect, useState } from "react";
import TokenExpirationChecker from "./services/tokenExpirationChecker";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import Issues from "./components/Issues/Issues";
import Exclude from "./components/Exclude/Exclude";
import Header from "./components/Header/Header";
import NotFound from "./components/NotFound";

let isInitial = true;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [sessionExpiredIndecator, setSessionExpiredIndacator] = useState(false)

  return (
    <>
      <Router>
        <TokenExpirationChecker setSessionExpiredIndacator={setSessionExpiredIndacator} />
          <Routes>
            <Route
              exact
              path="/"
              element={
                isAuthenticated ? (
                  <>
                    <Navigate to="/issues" />
                  </>
                ) : (
                  <>
                    <Navigate to="login" />
                  </>
                )
              }
            />

          <Route
            exact
            path="/login"
            element={
              <Login
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                sessionExpiredIndecator={sessionExpiredIndecator}
              />
            }
          ></Route>

          {/* {isAuthenticated && (
            <Route
              path="/issues"
              element={
                <>
                  <Header setIsAuthenticated={setIsAuthenticated} />
                  <Issues
                    setIsAuthenticated={setIsAuthenticated}
                    isInitial={isInitial}
                  />
                </>
              }
            ></Route>
          )} */}

          {isAuthenticated && (
            <Route
              path="/issues"
              element={
                <>
                  <Header setIsAuthenticated={setIsAuthenticated} />
                  <Issues
                    setIsAuthenticated={setIsAuthenticated}
                    isInitial={isInitial}
                  />
                </>
              }
            ></Route>
          )}
          {isAuthenticated && (
            <Route
              exact
              path="/exclude"
              element={
                <>
                  <Header setIsAuthenticated={setIsAuthenticated} />
                  <Exclude setIsAuthenticated={setIsAuthenticated} />
                </>
              }
            ></Route>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default App;
