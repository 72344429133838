import { useState, useEffect } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

const CopyButton = (props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <>
      {!copied ? (
        <ContentCopyIcon
          style={{
            cursor: "pointer",
            strokeDasharray: "50",
            transition: "all 300ms ease-in-out",
          }}
          onClick={() => {
            navigator.clipboard.writeText(props.text);
            setCopied(true);
          }}
        />
      ) : (
        <DoneIcon />
      )}
    </>
  );
};

export default CopyButton;
