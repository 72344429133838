import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteExcludeItem } from "../../../services/api";
import DeleteModal from "./DeleteModal";
import { useNavigate } from 'react-router-dom';

const DeleteItem = ({ data, setData, row, index, id }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  const onDeleteClick = async () => {
    try {
      const deletedItem = data.find((item) => item.id === id);
      await deleteExcludeItem(deletedItem);
      let dataCopy = data.filter((el) => el.id !== deletedItem.id);
      setData(dataCopy);
    } catch (error) {
      if (Number(error.message) === 401) {
        navigate('/login');
      }
    }
  };

  return (
    <>
      <DeleteIcon
        onClick={() => {
          setItemToDelete(row);
          setOpenModal(true);
        }}
        sx={{
          flex: 1,
          cursor: "pointer",
        }}
        color="error"
      />
      <DeleteModal
        openModal={openModal}
        onDeleteClick={onDeleteClick}
        setOpenModal={setOpenModal}
        item={itemToDelete}
      />
    </>
  );
};

export default DeleteItem;
