import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import HeaderButton from "./HeaderButton";
// import { useNavigate, useLocation, NavLink } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.clear("token");
    props.setIsAuthenticated(false);
    navigate("/login");
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "1rem",
        width: "100vw",
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: "#d80e84" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "flex-start" }}>
          <img src="images/vbet_logo.png" alt="vbet logo" />
          <Box
            sx={{
              display: "flex",
              gap: "80px",
              marginLeft: "100px",
              alignItems: "center",
            }}
          >
            <HeaderButton name="Translation Issues" pathname="issues" />
            <HeaderButton name="Exclude List" pathname="exclude" />
          </Box>

          <Button
            color="inherit"
            onClick={logoutHandler}
            sx={{ marginLeft: "45%" }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
