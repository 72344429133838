import React, { useCallback, useState, useEffect } from "react";
import CardWrapper from "../UI/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchExcludeList } from "../../services/api";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import AddExcludeItem from "./AddExcludeItem";
import EditItem from "./EditItem";
import DeleteItem from "./Delete/DeleteItem";
import { useNavigate } from 'react-router-dom';

const Exclude = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);


  const fetchData = useCallback(async () => {
    try {
      const data = await fetchExcludeList();
      setData(data);
      setIsLoading(false);
    } catch (error) {
      if(Number(error.message) === 401) {
        navigate('/login');
      }
      // setError(error);
    }
  }, [setData, setIsLoading, navigate]);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  if (refresh) {
    fetchData();
    setRefresh(false);
  }

  return (
    <>
      <div className="container">
        {!isLoading && !error && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Name
                  </TableCell>
                  <TableCell>
                    <AddExcludeItem
                      data={data}
                      setData={setData}
                      setRefresh={setRefresh}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow sx={{ display: "flex", alignItems: "center" }}>
                    <TableCell align="left" sx={{ flex: 5 }}>
                      <EditItem
                        itemName={row.item}
                        id={row.id}
                        index={index}
                        data={data}
                        setData={setData}
                      />
                    </TableCell>
                    <DeleteItem
                      id={row.id}
                      data={data}
                      setData={setData}
                      row={row}
                      index={index}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isLoading && (
          <CardWrapper>
            <CircularProgress size="1.5rem" />
          </CardWrapper>
        )}
        {!isLoading && error && (
          <CardWrapper>
            <p>{error}</p>
          </CardWrapper>
        )}
      </div>
    </>
  );
};

export default Exclude;
