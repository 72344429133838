import React, { useCallback, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { editExcludeItem } from "../../services/api";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EditItem = ({ id, data, setData, index, itemName }) => {
  const navigate = useNavigate();
  const [item, setItem] = useState(data[index].item);
  const [clicked, setClicked] = useState(false);
  const div = useRef(false);
  useEffect(() => {
    setItem(data[index].item);
  }, [data, index]);

  const onEdit = useCallback(async () => {
    try {
      if (item) {
        const editedItem = {
          name: item,
          id: id,
        };

        if (editedItem.name !== itemName) {
          await editExcludeItem([editedItem]);
          let dataCopy = data.slice(0);
          dataCopy[index].item = item;
          setData(dataCopy);
        }
      }
    } catch (error) {
      if (error.message === 401) {
        navigate("/login");
      }
    }
  }, [item, id, index, data, setData, navigate, itemName]);

  return (
    <Box ref={div}>
      {clicked && (
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <TextField
            id="standard-basic"
            label="Edit Item"
            variant="standard"
            sx={{ align: "left", marginTop: "3%" }}
            value={item}
            onChange={(data) => {
              setItem(data.target.value);
            }}
          />
          <CancelIcon onClick={() => setClicked(false)} color="error" />
          <CheckCircleOutlineIcon
            onClick={() => {
              onEdit();
              setClicked(false);
            }}
            color="success"
          />
        </Box>
      )}
      {!clicked && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EditIcon
            sx={{ marginRight: "2%" }}
            onClick={() => {
              setClicked(true);
              div(false);
            }}
          />
          <Typography xs={{ fontSize: "16px", display: "flex" }}>
            {itemName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditItem;
