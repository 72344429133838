import React, { useState, useCallback } from "react";
import Searchbar from "./Searchbar/Searchbar";
import DataTable from "./Table/DataTable";
import CardWrapper from "../UI/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchData, fetchNewToken } from "../../services/api";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Issues = ({ isInitial, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onBottom, setOnBottom] = useState(false);
  const [error, setError] = useState(null);
  const [additionalLoading, setAdditionalLoading] = useState(false);
  const [offset, setOffset] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [errorsCount, setErrorsCount] = useState(0)

  // let offset = 0;
  let count = 200;

  const searchRequestHandler = async (
    skin,
    language,
    section,
    level,
    startDate,
    endDate,
    count
  ) => {
    // console.log("start",startDate.$d.toISOString().split('T')[0], "end", endDate.$d.toISOString().split('T')[0])
    if (errorsCount > offset) {
      setIsLoading(true);
      setError(null);
      try {
        let data = await fetchData(
          level,
          language,
          section,
          skin,
          count,
          endDate,
          startDate,
          offset,
          () => {
            navigate("/login");
          }
        );
        setData(data);
        setOffset(offset + Number(count));
        isInitial = false;
      } catch (error) {
        if (Number(error.message) === 401) {
          navigate("/login");
        }
      }
      setIsLoading(false);
    }
  };

  // const handleOnDocumentBottom = useCallback(async () => {
  //   setOnBottom(true);
  // }, []);
  // useBottomScrollListener(handleOnDocumentBottom);

  return (
    <>
      <div className="container">
        <Searchbar
          onSearch={searchRequestHandler}
          offset={offset}
          data={data}
          setData={setData}
          count={count}
          onBottom={onBottom}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setAdditionalLoading={setAdditionalLoading}
          setOffset={setOffset}
          isVisible={isVisible}
          errorsCount={errorsCount}
          setErrorsCount={setErrorsCount}
        />
        {isLoading && (
          <CardWrapper>
            <CircularProgress size="1.5rem" />
          </CardWrapper>
        )}
        {!isLoading && !isInitial && data.length === 0 && (
          <CardWrapper>
            <p>No results found!</p>
          </CardWrapper>
        )}

        {!isLoading && data?.length > 0 && (
          <DataTable
            data={data}
            setData={setData}
            offset={offset}
            count={count}
            additionalLoading={additionalLoading}
            setIsVisible={setIsVisible}
            errorsCount={errorsCount}
          />
        )}

        {!isLoading && error && (
          <CardWrapper>
            <p>{error}</p>
          </CardWrapper>
        )}
      </div>
    </>
  );
};

export default Issues;
