import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function TokenExpirationChecker({setSessionExpiredIndacator}) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
        navigate('/login');
    } else {
      // check if token is expired
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationDate = new Date(payload.time * 1000);

      if (expirationDate < Date.now()) {
        setSessionExpiredIndacator(true)
        setTimeout(() => {
          setSessionExpiredIndacator(false)
        }, 3000);
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, [navigate]);

  return null;
}

export default TokenExpirationChecker;
