import { useState, useEffect } from "react";

import { useInView } from "react-intersection-observer";

import Form from "./Form";
import DateTime from "./DateTime";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import { fetchData } from "../../../services/api";
import { fetchCountOfErrors } from "../../../services/api";

const Searchbar = ({
  onSearch,
  offset,
  setOffset,
  setData,
  data,
  isLoading,
  count,
  isVisible,
  errorsCount,
  setErrorsCount,
  setAdditionalLoading
}) => {
  const [skin, setSkin] = useState("");
  const [language, setLanguage] = useState("");
  const [section, setSection] = useState(2);
  const [level, setLevel] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [count, setCount] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [viewPortDown, setViewPortDown] = useState(false);

  const { ref, inView, entry } = useInView({
    threshold: 0,
  })

  const skinChangeHandler = (event) => {
    setSkin(event.target.value);
    setOffset(0)
  };

  const languageChangeHandler = (event) => {
    setLanguage(event.target.value);
    setOffset(0)
  };

  const sectionChangeHandler = (event) => {
    setSection(event.target.value);
    setOffset(0)
  };

  const levelChangeHandler = (event) => {
    setLevel(event.target.value);
  };

  const startDateChangeHandler = (newValue) => {
    setStartDate(newValue.$d.toISOString().split('T')[0]);
  };

  const endDateChangeHandler = (newValue) => {
    setEndDate(newValue.$d.toISOString().split('T')[0]);
  };

  // const countChangeHandler = (event) => {
  //   setCount(event.target.value);
  // };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (skin !== "" && language !== "" && section !== "") {
          onSearch(skin, language, section, level, startDate, endDate, count);
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [skin, language, section, level, startDate, endDate, count]);


  useEffect(() => {
    (async () => {
      if (isVisible && (errorsCount > offset)) {
        setOffset(offset + Number(count));
        setAdditionalLoading(true)
        const response = await fetchData(
          level,
          language,
          section,
          skin,
          count,
          endDate,
          startDate,
          offset
        );
        const newData = [];
        newData.push(...response);
        setData((oldData) => [...oldData, ...newData]);
      }
      setAdditionalLoading(false)
    }
    )()
  }, [isVisible])

  useEffect(() => {
    if (skin && language && section) {
      (async () => {
        const response = await fetchCountOfErrors(skin, language, section)

        setErrorsCount(response)
      }
      )()
    }
  }, [skin, language, section])


  // useEffect(() => {
  //   window.addEventListener("scroll", async () => {
  //     if (
  //       isVisible
  //     ) {
  //       console.log("jaskhdfksahdfk");
  //       setOffset(offset + Number(count));
  //       let response = await fetchData(
  //         level,
  //         language,
  //         section,
  //         skin,
  //         count,
  //         endDate,
  //         startDate,
  //         offset
  //       );


  //     }
  //   });
  //   // return () => {
  //   //   window.removeEventListener("scroll", );
  //   // }
  // }, [data, level, language, section, skin, endDate, startDate, offset, setData, setOffset]);

  const resetHandler = () => {
    setLevel("");
    setStartDate("");
    setEndDate("");
    // setCount("");
  };
  useEffect(() => {
    if (skin !== "" && language !== "" && section !== "") {
      setDisabled(false);
    }
    if (isLoading) {
      setDisabled(true);
    }
  }, [skin, language, section, isLoading]);

  return (
    <Card sx={{ marginBottom: "3rem", width: "90rem" }}>
      <CardContent
        sx={{ display: "flex", justifyContent: "space-evenly" }}
        component={Paper}
      >
        <Form
          skin={skin}
          language={language}
          section={section}
          level={level}
          onSkinChange={skinChangeHandler}
          onLanguageChange={languageChangeHandler}
          onSectionChange={sectionChangeHandler}
          onLevelChange={levelChangeHandler}
        />
        <DateTime
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={startDateChangeHandler}
          onEndDateChange={endDateChangeHandler}
        />
        {/* <TextField
          disabled={true}
          sx={{ width: "6rem" }}
          label="Number"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          value={count}
          // onChange={countChangeHandler}
        /> */}
        <Button onClick={resetHandler}>Reset</Button>
        <Button
          disabled={disabled}
          onClick={() => {
            onSearch(skin, language, section, level, startDate, endDate, count);
          }}
        >
          Search
        </Button>
      </CardContent>
    </Card>
  );
};

export default Searchbar;
